const BASE_URL = "https://services.kuddly.app:8080/api/v2";
const DEFAULT_HEADERS = { "Content-Type": "application/json" };

/**
 * Sends a request to check the status of the reset password token
 *
 * @param {string} unencryptedToken
 */
export function checkTokenStatus(unencryptedToken) {
  return fetch(
    `${BASE_URL}/reset-password-tokens/status?${new URLSearchParams({ unencryptedToken })}`,
    {
      method: "GET",
    },
  );
}

/**
 * Sends a request to update the user's password
 *
 * @param {number} userId
 * @param {string} unencryptedToken
 * @param {string} unencryptedPassword
 */
export function resetPassword(userId, unencryptedToken, unencryptedPassword) {
  return fetch(
    `${BASE_URL}/users/${userId}/reset-password?${new URLSearchParams({ unencryptedToken })}`,
    {
      method: "POST",
      headers: DEFAULT_HEADERS,
      body: JSON.stringify({ unencryptedPassword }),
    },
  );
}

/**
 * Sends contact form request
 *
 * @param {Object} contactFormData - The contact form field data
 * @param {string} contactFormData.fullName - The user's full name
 * @param {string} contactFormData.senderEmail - The user's email
 * @param {string} contactFormData.message - The user's message
 */
export function contact({ fullName, senderEmail, message }) {
  return fetch(`${BASE_URL}/emails/contact`, {
    method: "POST",
    headers: DEFAULT_HEADERS,
    body: JSON.stringify({
      fullName,
      senderEmail,
      message,
    }),
  });
}
