import { useEffect, useState } from 'react';

import Form from '../components/ResetPassword/Form';
import KeyError from '../components/ResetPassword/KeyError';
import HeaderSmall from '../components/Layout/HeaderSmall';
import Footer from '../components/Layout/Footer';
import { checkTokenStatus } from '../api';

function NotFound() {
  const [isLoading, setIsLoading] = useState(true);
  const [keyExists, setKeyExists] = useState(false);
  const [keyExpired, setKeyExpired] = useState(false);
  const [keyConsumed, setKeyConsumed] = useState(false);
  const [userId, setUserId] = useState(0);

  const resetPasswordToken = window.location.pathname.split('/').pop();

  useEffect(() => {
    checkTokenStatus(resetPasswordToken)
      .then(response => {
        if (response.status === 200) {
          response.text().then(text => {
            const body = JSON.parse(text);
            setUserId(body.userId);
            if (body.status === 'VALID') {
              setKeyExists(true);
            } else if (body.status === 'EXPIRED') {
              setKeyExists(true);
              setKeyExpired(true);
            } else if (body.status === 'CONSUMED') {
              setKeyExists(true);
              setKeyConsumed(true);
            } else {
              throw new Error('Unknown response: ' + body);
            }
          });
        }
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
      })
      .catch(err => {
        setTimeout(() => {
          setIsLoading(false);
        }, 100);
        console.error(err);
      });
  }, [resetPasswordToken]);

  return (
    <>
      <HeaderSmall />
      {isLoading && <div className="loader"></div>}

      {!isLoading && keyExists && !keyExpired && !keyConsumed && (
        <Form userId={userId} token={resetPasswordToken} />
      )}
      {!isLoading && (!keyExists || keyExpired || keyConsumed) && (
        <KeyError keyExpired={keyExpired} keyConsumed={keyConsumed} />
      )}

      <Footer />
    </>
  );
}

export default NotFound;
